import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import history from './history';
import WithTracker from './components/WithTracker';
import AppHeader from './components/AppHeader';
import Scanner from './components/Scanner';

import FrontPage from './pages/FrontPage';
import BrandPicker from './pages/BrandPicker';
import Product from './pages/Product';
import ProductList from './pages/ProductList';
import PageNotFound from './pages/PageNotFound';
import ScannerPage from './pages/ScannerPage';

import './assets/css/reset.css';
import './assets/css/main.css';
import './assets/css/buttons.css';
import './assets/css/helpers.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onActive(event) {
    console.log('user is active', event);

    console.log('time remaining', this.idleTimer.getRemainingTime());
  }

  _onIdle(event) {
    if (history.location.pathname !== '/') {
      history.replace('/');
    }
  }

  render() {
    return (
      <Router history={history}>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          timeout={1000 * 60}
        >
          <Scanner history={history}>
            <div className="App">
              <AppHeader history={history} />
              <Switch>
                <Route exact path="/" component={WithTracker(FrontPage)} />
                <Route
                  path="/scanna-dosa"
                  component={WithTracker(ScannerPage)}
                />
                <Route
                  path="/valj-varumarke"
                  component={WithTracker(BrandPicker)}
                />
                <Route
                  path="/hitta-produkt"
                  component={WithTracker(ProductList)}
                />
                <Route
                  path="/produkt/:productId"
                  component={WithTracker(Product)}
                />
                <Route component={WithTracker(PageNotFound)} />
              </Switch>
            </div>
          </Scanner>
        </IdleTimer>
      </Router>
    );
  }
}

export default App;
