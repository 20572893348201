import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import decode from 'parse-entities';
import { CSSTransition } from 'react-transition-group';

import LazyLoad from 'react-lazy-load';
import Spinner from '../components/UI/Spinner';

import '../assets/css/transitions.css';
import './BrandPicker.css';

class BrandItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoaded: false
    };

    this.setImageLoaded = this.setImageLoaded.bind(this);
  }

  setImageLoaded() {
    this.setState({
      imageLoaded: true
    });
  }

  render() {
    const props = this.props;

    return (
      <li className="Brand-item" key={props.id}>
        <Link to={`/hitta-produkt?product-trademarks=${props.id}`}>
          {props.logoImage && props.logoImage.length > 0 ? (
            <LazyLoad
              offsetVertical={100}
              timeout={500}
              onContentVisible={this.setImageLoaded}
            >
              <CSSTransition
                in={this.state.imageLoaded}
                timeout={500}
                classNames="fade"
              >
                <img
                  src={props.logoImage[0]}
                  width={props.logoImage[1]}
                  height={props.logoImage[2]}
                  alt={props.title}
                  className="responsive-img"
                />
              </CSSTransition>
            </LazyLoad>
          ) : (
            decode(props.title)
          )}
        </Link>
        <span className="product-count">({props.count})</span>
      </li>
    );
  }
}

class BrandPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true
    };

    this.controller = null;
  }

  updateBrands() {
    this.fetchBrands()
      .then(data => {
        this.setState({
          data,
          loading: false
        });
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Uh oh, an error!', err);
        }
      });
  }

  async fetchBrands() {
    this.controller = new AbortController();
    const signal = this.controller.signal;

    const apiBase = process.env.REACT_APP_API_BASE;

    try {
      const response = await fetch(
        `${apiBase}/v2/product-trademarks?hide_empty=1&per_page=100`,
        { signal }
      );
      return response.json();
    } catch (err) {
      throw err;
    }
  }

  componentDidMount() {
    this.updateBrands();
  }

  componentWillUnmount() {
    this.abortUpdate();
  }

  abortUpdate() {
    if (this.controller) {
      this.controller.abort();
    }
  }

  render() {
    const { data, loading } = this.state;

    if (loading) {
      return <Spinner />;
    }

    const items =
      data.map(item => {
        return (
          <BrandItem
            key={item.id}
            id={item.id}
            title={item.name}
            count={item.count}
            logoImage={item.logo_image}
          />
        );
      }) || [];

    return (
      <div className="container">
        <h1>Välj varumärke</h1>
        <ul className="BrandList">{items}</ul>
      </div>
    );
  }
}

export default BrandPicker;
