import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';
import { CSSTransition } from 'react-transition-group';
import decode from 'parse-entities';

import Spinner from '../components/UI/Spinner';
import NewsSymbol from '../components/Product/NewsSymbol';
import PriceList from '../components/Product/PriceList';

import MissingImg from '../assets/images/img_missing.jpg';

import '../assets/css/transitions.css';
import './Product.css';

// Functional component for the ProductHeader
const ProductHeader = props => {
  const norTitle = props.noTitle ? (
    <div className="Product-title-lang">{decode(props.noTitle)} (NO)</div>
  ) : null;

  return (
    <header className="Product-header">
      <h1 className="Product-title">
        <div className="Product-title-lang">{decode(props.title.rendered)}</div>
        {norTitle}
      </h1>
    </header>
  );
};

// Functional component for the ProductContent
const ProductContent = props => {
  return (
    <div
      className="Product-content"
      dangerouslySetInnerHTML={{
        __html: props.content.rendered
      }}
    />
  );
};

// Functional component for the ProductInfo
const ProductInfo = props => {
  const listItems = props.listData.map((item, index) => {
    if (!item.value) {
      return null;
    }

    return (
      <li className="ProductInfo-item" key={index}>
        <strong>{item.label}:</strong> {decode(item.value)}
      </li>
    );
  });

  return (
    <div className="Product-info">
      <h3 className="Product-sub-title">{decode(props.title)}</h3>
      <ul className="Product-info-list">{listItems}</ul>
    </div>
  );
};

// Functional component for the ProductDesign
const ProductDesign = props => {
  if (!props.imageData || props.imageData.length <= 0) {
    return (
      <div className="Product-design">
        <h3 className="Product-sub-title">{decode(props.title)}</h3>
        <div className="Product-img-wrapper">
          <NewsSymbol isNews={props.isNews} />
          <LazyLoad
            offsetVertical={100}
            onContentVisible={props.setImageLoaded}
          >
            <CSSTransition
              in={props.imageLoaded}
              timeout={500}
              classNames="fade"
            >
              <img
                src={MissingImg}
                alt="Saknar produktdesign"
                className="responsive-img"
              />
            </CSSTransition>
          </LazyLoad>
        </div>
      </div>
    );
  }

  const [src, width, height] = props.imageData;

  return (
    <div className="Product-design">
      <h3 className="Product-sub-title">{decode(props.title)}</h3>
      <div className="Product-img-wrapper">
        <NewsSymbol isNews={props.isNews} />
        <LazyLoad offsetVertical={100} onContentVisible={props.setImageLoaded}>
          <CSSTransition in={props.imageLoaded} classNames="fade">
            <img
              src={src}
              width={width}
              height={height}
              alt="Produkt design"
              className="responsive-img"
            />
          </CSSTransition>
        </LazyLoad>
      </div>
    </div>
  );
};

class Product extends Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      loading: true,
      imageLoaded: false,
      data: {}
    };

    this.controller = null;

    // Bind this scope
    this.setImageLoaded = this.setImageLoaded.bind(this);
  }

  componentDidMount() {
    // Fetch the product data
    this.updateData();
  }

  componentWillUnmount() {
    this.abortUpdate();
  }

  setImageLoaded() {
    this.setState({
      imageLoaded: true
    });
  }

  updateData() {
    // If state is not loading, set the state to loading to true
    if (!this.state.loading) {
      this.setState({
        loading: true,
        data: {}
      });
    }

    // Fetch the needed product data
    this.fetchData()
      .then(data => {
        this.setState({
          loading: false,
          data
        });
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Uh oh, an error!', err);
        }
      });
  }

  abortUpdate() {
    if (this.controller) {
      this.controller.abort();
    }
  }

  componentDidUpdate(prevProps) {
    const { productId } = this.props.match.params,
      oldProductId = prevProps.match.params.productId;

    // Check if we changed productId between update, then fetch new data
    if (productId !== oldProductId) {
      this.updateData(); // Update the view
    }
  }

  async fetchData() {
    const { productId } = this.props.match.params;

    this.controller = new AbortController();
    const signal = this.controller.signal;

    const apiBase = process.env.REACT_APP_API_BASE;

    try {
      const response = await fetch(
        `${apiBase}/v2/products/${productId}?_embed=1`,
        { signal }
      );
      return response.json();
    } catch (err) {
      throw err;
    }
  }

  render() {
    const { data, loading, imageLoaded } = this.state;

    if (loading) {
      return <Spinner />;
    }

    // Get and set the product terms in their respective groups
    const [brands, types, strengths, formats] = data['_embedded']['wp:term'];

    return (
      <div className="container">
        <article className="Product">
          <ProductHeader title={data.title} noTitle={data.no_title} />
          <ProductContent content={data.content} />
          <div className="Product-details">
            <ProductDesign
              title="Design"
              imageData={data.product_image || null}
              isNews={data.is_news}
              imageLoaded={imageLoaded}
              setImageLoaded={this.setImageLoaded}
            />
            <PriceList title="Pris" prices={data.product_prices} />
            <ProductInfo
              title="Information"
              listData={[
                { label: 'Varumärke', value: brands[0].name || null },
                { label: 'Typ', value: types[0].name || null },
                { label: 'Vikt', value: data.weight || null },
                { label: 'Format', value: formats[0].name || null },
                { label: 'Styrka', value: strengths[0].name || null },
                { label: 'Smak', value: data.taste || null },
                { label: 'Producent', value: data.supplier || null }
              ]}
            />
          </div>
        </article>
      </div>
    );
  }
}

export default Product;
