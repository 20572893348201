import React, { Component } from 'react';
import decode from 'parse-entities';

import './SelectField.css';

class SelectField extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { setFilters, taxonomy } = this.props;
    const value = event.target.value;

    // Update the parent component which saves the state
    setFilters(taxonomy, value);
  }

  render() {
    const { label, items, disabled, value, name } = this.props;

    const allOpt = (
      <option value="*" key="*">
        Alla
      </option>
    );

    const options =
      [
        allOpt,
        ...items.map(item => {
          return (
            <option value={item.id} key={item.id}>
              {decode(item.name)}
            </option>
          );
        })
      ] || null;

    return (
      <div className="SelectField-wrapper">
        <label htmlFor={name}>{label}</label>
        <select
          className="SelectField"
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          id={name}
        >
          {options}
        </select>
        <div className="SelectField-arrow">
          <svg className="icon icon-chevron-down">
            <use xlinkHref="#icon-chevron-down" />
          </svg>
        </div>
      </div>
    );
  }
}

export default SelectField;
