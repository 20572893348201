import React, { Component } from 'react';
import ReactGA from 'react-ga';

// Initialize Google Analytics (but only on production)
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-130660648-1');
}

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = page => {
    // Track pageview (but only on production)
    if (process.env.NODE_ENV === 'production') {
      ReactGA.set({
        page,
        ...options
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = class extends Component {
    componentDidMount() {
      const url = this.props.location.pathname + this.props.location.search;
      trackPage(url);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage && currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
