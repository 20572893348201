import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './ScannerPage.css';

const ExplanationList = props => {
  return <ul className="Scanner-explanation">{props.children}</ul>;
};

const ExplanationItem = props => {
  return (
    <li className="ExplanationItem">
      {props.icon}
      <h2>{props.title}</h2>
      <p>{props.description}</p>
    </li>
  );
};

class ScannerPage extends Component {
  render() {
    const handIcon = (
      <div className="explanation-icon">
        <svg className="icon icon-hand">
          <use xlinkHref="#icon-hand" />
        </svg>
      </div>
    );

    const barcodeIcon = (
      <div className="explanation-icon">
        <svg className="icon icon-minus">
          <use xlinkHref="#icon-minus" />
        </svg>
        <svg className="icon icon-barcode">
          <use xlinkHref="#icon-barcode" />
        </svg>
      </div>
    );

    const checkIcon = (
      <div className="explanation-icon">
        <svg className="icon icon-spinner3">
          <use xlinkHref="#icon-spinner3" />
        </svg>
        <svg className="icon icon-checkmark">
          <use xlinkHref="#icon-checkmark" />
        </svg>
      </div>
    );

    return (
      <div className="ScannerPage-container container">
        <h1 className="ScannerPage-title">Så här scannar du</h1>
        <ExplanationList>
          <ExplanationItem
            icon={handIcon}
            title="1. Ta scannern"
            description="Ta loss scannern från sitt hölje."
          />
          <ExplanationItem
            icon={barcodeIcon}
            title="2. Scanna streckkoden"
            description="Rikta scannern mot snusdosans streckkod och tryck på knappen för att avsluta scanningen."
          />
          <ExplanationItem
            icon={checkIcon}
            title="3. Voila!"
            description="Vid lyckad scanning tas du automatiskt till produktsidan om dosan finns i vårt sortiment."
          />
        </ExplanationList>
        <div className="ScannerPage-body">
          <p>
            Om du får problem,{' '}
            <Link to="/hitta-produkt">använd vår produktsök istället</Link>.
          </p>
        </div>
      </div>
    );
  }
}

export default ScannerPage;
