import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import SearchForm from '../components/SearchForm';

import './AppHeader.css';

const Menu = props => {
  return <ul className="Menu">{props.children}</ul>;
};

const HistoryItem = props => {
  const { history } = props;

  // If there is no history to go back to, do not render!
  if (history.length < 1) {
    return null;
  }

  return (
    <li className="Menu-item Menu-item-icon">
      <a
        href="/"
        onClick={event => {
          history.goBack();
          event.preventDefault();
        }}
        className="HistoryLink"
        title="Tillbaka till föregående sida"
        aria-label="Tillbaka till föregående sida"
      >
        <svg className="icon icon-arrow-left2">
          <use xlinkHref="#icon-arrow-left2" />
        </svg>
      </a>
    </li>
  );
};

const MenuItem = props => {
  return (
    <li className="Menu-item Menu-item-icon">
      <Link to={props.href} title={props.title} aria-label={props.title}>
        <svg className={`icon icon-${props.icon}`}>
          <use xlinkHref={`#icon-${props.icon}`} />
        </svg>
        {props.linkText}
      </Link>
    </li>
  );
};

class AppHeader extends Component {
  render() {
    const { history } = this.props,
      { pathname } = history.location;

    if (pathname === '/') {
      return null;
    }

    return (
      <header className="AppHeader">
        <div className="container">
          <div className="AppHeader-container">
            {window.location.pathname === '/' ? <div /> : null}
            <nav className="AppNavigation">
              <Menu>
                <HistoryItem history={history} />
                <MenuItem
                  href="/"
                  title="Till startsidan"
                  linkText="Hem"
                  icon="home"
                />
              </Menu>
              <Menu>
                <MenuItem
                  href="/scanna-dosa"
                  title="Scanna din dosa"
                  linkText="Scanna"
                  icon="barcode"
                />
                <MenuItem
                  href="/valj-varumarke"
                  title="Våra varumärken"
                  linkText="Varumärken"
                  icon="folder"
                />
                <MenuItem
                  href="/hitta-produkt"
                  title="Våra produkter"
                  linkText="Produkter"
                  icon="database"
                />
              </Menu>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default AppHeader;
