import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './FrontPage.css';

import logoSrc from '../assets/images/logo_red.png';

const PagesList = props => {
  return <ul className="PagesList">{props.children}</ul>;
};

const PageItem = props => {
  return (
    <li className="PageItem">
      <Link to={props.to} className="PageItem-button">
        <div className="PageItem-circle">
          <svg className={`icon icon-${props.icon}`}>
            <use xlinkHref={`#icon-${props.icon}`} />
          </svg>
        </div>
        <h3 className="PageItem-title">{props.title}</h3>
      </Link>
    </li>
  );
};

class FrontPage extends Component {
  render() {
    return (
      <div className="FrontPage-container container">
        <h1>
          <img
            src={logoSrc}
            alt="Logotyp: Snus by Gottebiten"
            className="ShopLogo"
          />
        </h1>
        <h2 className="FrontPage-title">Sök efter snus och se våra priser.</h2>
        <h2 className="FrontPage-title">Du har 3 alternativ:</h2>
        <PagesList>
          <PageItem to="/scanna-dosa" icon="barcode" title="Scanna din dosa" />
          <PageItem
            to="/valj-varumarke"
            icon="folder"
            title="Sök på varumärke"
          />
          <PageItem
            to="/hitta-produkt"
            icon="database"
            title="Sök på produkt"
          />
        </PagesList>
      </div>
    );
  }
}

export default FrontPage;
