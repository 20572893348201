import React, { PureComponent } from 'react';

import './Spinner.css';

class Spinner extends PureComponent {
  render() {
    return (
      <div className="Spinner">
        <svg className="icon icon-spinner3">
          <use xlinkHref="#icon-spinner3" />
        </svg>
      </div>
    );
  }
}

export default Spinner;
