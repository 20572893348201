import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import noScroll from 'no-scroll';

import './Modal.css';

const modalRoot = document.getElementById('modal-root');

class ModalDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initiated: false
    };

    this.cancelBtn = React.createRef();

    this.focusCancelBtn = this.focusCancelBtn.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      initiated: true
    });
  }

  componentDidUpdate() {
    this.focusCancelBtn();
  }

  focusCancelBtn() {
    this.cancelBtn.current.focus();
  }

  handleEscape(event) {
    const { handleHide } = this.props;
    if (event.keyCode === 27 && typeof handleHide === 'function') {
      handleHide();
    }
  }

  handleClick(event) {
    const { handleHide } = this.props;
    if (event.target.tagName !== 'IMG' && typeof handleHide === 'function') {
      handleHide();
    }
  }

  render() {
    const props = this.props;

    return (
      <div
        className="ModalDialog"
        aria-modal="true"
        role="dialog"
        onClick={this.handleClick}
        onKeyDown={this.handleEscape}
      >
        <div className="ModalDialog-wrapper">
          <div className="ModalDialog-content">
            <img
              src={props.imgSrc}
              alt={props.alt}
              className="responsive-img"
            />
            <button
              className="ModalDialog-close"
              id="modal-deactivate"
              ref={this.cancelBtn}
              autoFocus
              onClick={props.handleHide}
            >
              <svg className="icon icon-shrink2">
                <use xlinkHref="#icon-shrink2" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

class Modal extends Component {
  constructor(props) {
    super(props);
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.

    this.el = document.createElement('div');
  }

  componentDidMount() {
    // Append the element into the DOM on mount. We'll render
    // into the modal container element (see the HTML tab).
    modalRoot.setAttribute('aria-hidden', false);
    modalRoot.appendChild(this.el);

    // Activate no scroll on the document
    noScroll.on();
  }

  componentWillUnmount() {
    // Turn off no scroll on the document
    noScroll.off();

    // Remove the element from the DOM when we unmount
    modalRoot.setAttribute('aria-hidden', true);
    modalRoot.removeChild(this.el);
  }

  render() {
    const props = this.props;

    // Use a portal to render the children into the element
    return ReactDOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      <ModalDialog
        imgSrc={props.imgSrc}
        alt={props.alt}
        handleHide={props.handleHide}
      />,
      // A DOM element
      this.el
    );
  }
}

export default Modal;
