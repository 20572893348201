import React from 'react';

import './NewsSymbol.css';
import symbolSrc from '../../assets/svg/news_symbol.svg';

const NewsSymbol = props => {
  if (props.isNews) {
    return <img src={symbolSrc} alt="Nyhetsymbol" className="NewsSymbol" />;
  } else {
    return null;
  }
};

export default NewsSymbol;
