import React, { Component } from 'react';

import Modal from '../UI/Modal';
import './PriceList.css';

import MissingImg from '../../assets/images/img_missing.jpg';

class PriceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  handleShow() {
    this.setState({ showModal: true });
  }

  handleHide() {
    this.setState({ showModal: false });
  }

  render() {
    const props = this.props;

    const imgData = {
      thumbnailSrc:
        props.img.sizes && props.img.sizes.hasOwnProperty('thumbnail')
          ? props.img.sizes.thumbnail
          : MissingImg,
      largeSrc:
        props.img.sizes && props.img.sizes.hasOwnProperty('large')
          ? props.img.sizes.large
          : MissingImg,
      alt: props.img.alt || ''
    };

    const modal = this.state.showModal ? (
      <Modal
        imgSrc={imgData.largeSrc}
        alt={imgData.alt || ''}
        handleHide={this.handleHide}
      />
    ) : null;

    return (
      <li className="PriceItem">
        <div
          onClick={this.handleShow}
          role="button"
          className="PriceItem-thumbnail-preview"
        >
          <img
            src={imgData.thumbnailSrc}
            alt={imgData.alt}
            className="responsive-img"
          />
          <svg className="icon icon-enlarge2">
            <use xlinkHref="#icon-enlarge2" />
          </svg>
        </div>
        <div className="PriceItem-content">
          <span className="Price-title">
            <strong>{props.title}</strong> ({props.singlePrice || '0.00'}{' '}
            SEK/st)
          </span>
          <span className="Price-cost">
            <svg className="icon icon-price-tag">
              <use xlinkHref="#icon-price-tag" />
            </svg>
            <strong>{props.price}</strong> SEK
          </span>
        </div>
        {modal}
      </li>
    );
  }
}

class PriceList extends Component {
  render() {
    const { prices, title } = this.props;

    const priceItems =
      prices.length > 0 ? (
        prices.map((item, index) => {
          return (
            <PriceItem
              key={index}
              img={item.img}
              title={item.title}
              price={item.price}
              singlePrice={item.single_price}
              handleShow={this.handleShow}
            />
          );
        })
      ) : (
        <li>Prisuppgifter saknas, prata med butikspersonal angående pris.</li>
      );

    return (
      <div className="Product-prices">
        <h3 className="Product-sub-title">{title}</h3>
        <ul className="PriceList">{priceItems}</ul>
      </div>
    );
  }
}

export default PriceList;
