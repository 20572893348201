import React, { Component } from 'react';
import orderBy from 'lodash/orderBy';

import SelectField from '../components/Filters/SelectField';
import Checkbox from '../components/Filters/Checkbox';
import ProductItem from '../components/Product/ProductItem';
import Spinner from '../components/UI/Spinner';

import './ProductList.css';

// Function gets the current url parameter value
const getParamValue = type => {
  const { location } = window;
  const query = new URLSearchParams(location.search);
  return query.get(type) || '*';
};

// Functional component for the products
const ProductList = props => {
  const { products, loading, showNews } = props;

  if (loading) {
    return <Spinner />;
  }

  // Gather all skruf products in one collection and orderby prioritize news products first...
  const skrufProducts = orderBy(
    products.filter(item => {
      const brandId = item['product-trademarks'][0] || null;
      return brandId === 3;
    }),
    item => {
      return item.is_news === 1 ? 1 : -1;
    },
    'desc'
  );

  // Gather all other products inside another collection...
  const otherProducts = products.filter(item => {
    const brandId = item['product-trademarks'][0] || null;
    return brandId !== 3;
  });

  // Add the skruf products first, following the rest
  const sortedProducts = [...skrufProducts, ...otherProducts];

  const productsList = sortedProducts
    .filter(item => {
      return showNews ? item.is_news === 1 : true;
    })
    .map(item => {
      return (
        <ProductItem
          key={item.id}
          id={item.id}
          title={item.title.rendered}
          noTitle={item.no_title}
          supplier={item.supplier}
          isNews={item.is_news}
          featuredMediaId={item.featured_media}
          priceFrom={item.product_cost}
          thumbnailData={item.product_thumbnail}
        />
      );
    });

  if (productsList.length <= 0) {
    return (
      <div className="ProductsList-no-results">
        <h2>Inga produkter matchade dina sökkriterier</h2>
        <p>{props.renderResetBtn()}</p>
      </div>
    );
  }

  return <ul className="ProductsList">{productsList}</ul>;
};

class ProductsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlParams: {
        'product-trademarks': getParamValue('product-trademarks'),
        'product-types': getParamValue('product-types'),
        'product-strengths': getParamValue('product-strengths'),
        'product-formats': getParamValue('product-formats')
      },
      products: [],
      brands: [],
      types: [],
      strengths: [],
      formats: [],
      loading: true,
      showNews: false
    };

    this.controller = null;

    this.setUrlParams = this.setUrlParams.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.renderResetButton = this.renderResetButton.bind(this);
    this.showOnlyNews = this.showOnlyNews.bind(this);
  }

  fetchAllData() {
    this.fetchTerms()
      .then(termsData => {
        this.setState({
          brands: termsData[0],
          types: termsData[1],
          formats: termsData[2],
          strengths: termsData[3]
        });

        // Initiate the products list
        this.updateProductsList();
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Uh oh, an error!', err);
        }
      });
  }

  componentDidMount() {
    this.fetchAllData();
  }

  componentWillUnmount() {
    this.abortFetches();
  }

  abortFetches() {
    if (this.controller) {
      this.controller.abort();
    }
  }

  async fetchTerms() {
    const apiBase = process.env.REACT_APP_API_BASE;
    const urls = [
      `${apiBase}/v2/product-trademarks?hide_empty=1&per_page=100`,
      `${apiBase}/v2/product-types?hide_empty=1&per_page=100`,
      `${apiBase}/v2/product-formats?hide_empty=1&per_page=100`,
      `${apiBase}/v2/product-strengths?hide_empty=1&per_page=100`
    ];

    this.controller = new AbortController();
    const { signal } = this.controller;

    const termsFetches = urls.map(async url => {
      const response = await fetch(url, { signal });
      return response.json();
    });

    return Promise.all(termsFetches);
  }

  async fetchProducts() {
    const apiBase = process.env.REACT_APP_API_BASE;

    const { search } = window.location;
    const searchQuery = search ? search + `&per_page=100` : `?per_page=100`;

    this.controller = new AbortController();
    const { signal } = this.controller;

    const orderQuery = '&orderby=title&order=asc';
    const url = `${apiBase}/v2/products${searchQuery}${orderQuery}`;

    // Get the request headers
    const headResponse = await fetch(url, {
      signal,
      method: 'HEAD'
    });

    // Get the total amount of fetchable product pages
    const maxPages = await parseInt(
      headResponse.headers.get('X-WP-TotalPages'),
      10
    );

    // Create an array with all the product page urls that we need to fetch
    const urls = [...Array(maxPages)].map((x, i) => {
      const page = i + 1;
      return `${url}&page=${page}`;
    });

    const productFetches = urls.map(async url => {
      const response = await fetch(url, { signal });
      return response.json();
    });

    return Promise.all(productFetches);
  }

  componentDidUpdate(prevProps) {
    // Never update urlParams if the search parameter remains the same between renders
    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        urlParams: {
          'product-trademarks': getParamValue('product-trademarks'),
          'product-types': getParamValue('product-types'),
          'product-strengths': getParamValue('product-strengths'),
          'product-formats': getParamValue('product-formats')
        }
      });
      this.updateProductsList();
    }
  }

  setUrlParams(name, val) {
    const { urlParams } = this.state;

    // If urlParams does not exist or if its the same value as before, skip updating state then
    if (typeof urlParams[name] === 'undefined' && urlParams[name] === val) {
      return null;
    }

    // Update the url parameters fo
    this.updateUrlParams(name, val);
    this.setState({
      'product-trademarks': getParamValue('product-trademarks'),
      'product-types': getParamValue('product-types'),
      'product-strengths': getParamValue('product-strengths'),
      'product-formats': getParamValue('product-formats')
    });
  }

  updateUrlParams(name, value) {
    const { history } = this.props;
    const { location } = window;
    const query = new URLSearchParams(location.search);

    // Update search query parameters
    if (value === '*' || !value) {
      query.delete(name);
    } else {
      query.set(name, value);
    }

    // Build the updated urlpath
    const queryUrl = query.toString();
    const urlpath = queryUrl
      ? location.pathname + '?' + queryUrl
      : location.pathname;

    // Push the new state unto the app
    history.push(urlpath);
  }

  resetFilters() {
    const { history } = this.props,
      { location } = window;

    // Reset the state of all the active filters
    this.setState({
      urlParams: {
        'product-trademarks': '*',
        'product-types': '*',
        'product-strengths': '*',
        'product-formats': '*'
      },
      showNews: false
    });

    // Reset the url parameters
    history.push(location.pathname);
  }

  showOnlyNews(showNews = false) {
    this.setState({
      showNews
    });

    // Dispatch an resize event,
    // due to the lazyload component not updating otherwise
    window.dispatchEvent(new Event('resize'));
  }

  updateProductsList() {
    const { loading } = this.state;

    // Set the loading state to true if its false
    if (!loading) {
      this.setState({
        loading: true
      });
    }

    this.fetchProducts()
      .then(data => {
        const products = [].concat.apply([], data);

        this.setState({
          products,
          loading: false
        });

        // iOS safari bug fix
        // More info on the issue: https://github.com/vuejs/vue/issues/5533#issuecomment-301982923
        window.scrollTo(0, 1);
        window.scrollTo(0, 0);
      })
      .catch(err => console.log(err.message));
  }

  renderFilters() {
    const {
      brands,
      types,
      formats,
      strengths,
      loading,
      urlParams
    } = this.state;

    return (
      <div className="Filters-wrapper">
        {this.renderResetButton()}
        <div className="Product-filters">
          <SelectField
            label="Varumärken"
            items={brands}
            taxonomy="product-trademarks"
            setFilters={this.setUrlParams}
            disabled={loading}
            value={urlParams['product-trademarks']}
            name="product-trademarks-select"
          />
          <SelectField
            label="Typ"
            items={types}
            taxonomy="product-types"
            setFilters={this.setUrlParams}
            disabled={loading}
            value={urlParams['product-types']}
            name="product-types-select"
          />
          <SelectField
            label="Styrka"
            items={strengths}
            taxonomy="product-strengths"
            setFilters={this.setUrlParams}
            disabled={loading}
            value={urlParams['product-strengths']}
            name="product-strengths-select"
          />
          <SelectField
            label="Format"
            items={formats}
            taxonomy="product-formats"
            setFilters={this.setUrlParams}
            disabled={loading}
            value={urlParams['product-formats']}
            name="product-formats-select"
          />
          <Checkbox
            label="Visa endast nyheter"
            disabled={loading}
            name="product-news-checkbox"
            updateState={this.showOnlyNews}
            checked={this.state.showNews}
          />
        </div>
      </div>
    );
  }

  renderResetButton(btnText = 'Återställ filtren') {
    const { urlParams, loading, showNews } = this.state;
    const isActive = Object.entries(urlParams).some(([key, val]) => {
      return val !== '*';
    });

    return isActive || showNews ? (
      <button
        className="ResetFilters-button"
        onClick={this.resetFilters}
        disabled={loading}
      >
        <svg className="icon icon-cancel-circle">
          <use xlinkHref="#icon-cancel-circle" />
        </svg>
        {btnText}
      </button>
    ) : null;
  }

  render() {
    const { products, loading } = this.state;
    return (
      <div className="container">
        <h1>Hitta din produkt</h1>
        {this.renderFilters()}
        <ProductList
          products={products}
          loading={loading}
          renderResetBtn={this.renderResetButton}
          showNews={this.state.showNews}
        />
      </div>
    );
  }
}

export default ProductsList;
