import React, { Component } from 'react';

import './Checkbox.css';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target,
      value = target.checked;

    const { updateState } = this.props;

    // Call the parent component to update its state
    if (typeof updateState === 'function') {
      updateState(value);
    }
  }

  render() {
    const { label, disabled, name, checked } = this.props;
    return (
      <div className="Checkbox-wrapper">
        <label>
          <input
            type="checkbox"
            className="Checkbox"
            value="1"
            checked={checked}
            onChange={this.handleChange}
            disabled={disabled}
            id={name}
          />
          {label}
        </label>
      </div>
    );
  }
}

export default Checkbox;
