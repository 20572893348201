import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Spinner from './UI/Spinner';

import './Scanner.css';

/**
 * The form error message component.
 * @param {object} props
 */
function ScannerError(props) {
  if (props.message) {
    return (
      <p className="Scanner-Error">
        {props.message}
        <br />
        <Link to="/hitta-produkt" onClick={props.visiblitityCallback}>
          Använd vår produktsök istället
        </Link>
        .
      </p>
    );
  } else {
    return null;
  }
}

class Scanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: null,
      fetching: false,
      errorMessage: '',
      pressedKeys: [],
      time: 0
    };

    this.handleKeydown = this.handleKeydown.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    const { visible } = this.state;
    this.setState({
      visible: !visible
    });
  }

  // Method starts timer and then hides the error message after time has been passed
  startTimer() {
    const time = 10000;
    setTimeout(
      () =>
        this.setState({
          errorMessage: '',
          visible: false
        }),
      time
    );
  }

  doFetch(barcode) {
    this.setState({
      fetching: true,
      visible: true
    });

    this.fetchProduct(barcode)
      .then(data => {
        if (data.error_message) {
          this.startTimer();
          this.setState({
            fetching: false,
            errorMessage: data.error_message,
            productId: false,
            visible: true
          });
        } else {
          this.setState({
            fetching: false,
            errorMessage: null,
            productId: parseInt(data.product_id, 10),
            visible: false
          });
        }
      })
      .catch(err => console.log(err.message));
  }

  async fetchProduct(barcode = '') {
    try {
      const scannerBase = process.env.REACT_APP_SCANNER_BASE;
      const response = await fetch(`${scannerBase}/v1/scanner/${barcode}`);
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  }

  handleKeydown(event) {
    const { pressedKeys } = this.state;
    if (event.keyCode === 13) {
      const barcode = pressedKeys.join('');

      this.setState({
        pressedKeys: []
      });

      this.doFetch(barcode);
    } else {
      pressedKeys.push(event.key);
      this.setState({
        pressedKeys
      });
    }
  }

  renderScannerFlow() {
    const { visible, fetching, errorMessage } = this.state;

    const visibilityBtn = visible ? (
      <button
        className="Scanner-visiblity-button"
        onClick={this.toggleVisibility}
      >
        <svg className="icon icon-cross">
          <use xlinkHref="#icon-cross" />
        </svg>
      </button>
    ) : null;

    if (fetching && visible) {
      return (
        <div className="Scanner animate-in">
          <Spinner />
        </div>
      );
    } else if (errorMessage !== '' && visible) {
      return (
        <div className="Scanner animate-in">
          <ScannerError
            message={errorMessage}
            visiblitityCallback={this.toggleVisibility}
          />
          {visibilityBtn}
        </div>
      );
    } else {
      return <div className="Scanner" />;
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history } = this.props;
    const { productId } = this.state;

    if (prevState.productId !== productId && productId > 0) {
      this.setState({
        productId: null
      });
      history.push(`/produkt/${productId}`);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown, false);
  }

  render() {
    return (
      <div>
        {this.renderScannerFlow()}
        {this.props.children}
      </div>
    );
  }
}

export default Scanner;
