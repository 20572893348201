import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import decode from 'parse-entities';
import { CSSTransition } from 'react-transition-group';

import LazyLoad from 'react-lazy-load';
import NewsSymbol from './NewsSymbol';

import './ProductItem.css';
import '../../assets/css/transitions.css';

import MissingImg from '../../assets/images/img_missing.jpg';

const ProductItemThumbnail = props => {
  if (!props.imgData || props.imgData.length <= 0) {
    return (
      <LazyLoad offsetVertical={100} onContentVisible={props.setImageLoaded}>
        <CSSTransition in={props.imageLoaded} timeout={500} classNames="fade">
          <img
            src={MissingImg}
            alt="Saknar produktdesign"
            className="product-thumbnail responsive-img"
          />
        </CSSTransition>
      </LazyLoad>
    );
  }

  return (
    <LazyLoad offsetVertical={100} onContentVisible={props.setImageLoaded}>
      <CSSTransition in={props.imageLoaded} timeout={500} classNames="fade">
        <img
          src={props.imgData[0]}
          width={props.imgData[1]}
          height={props.imgData[2]}
          alt="Saknar produktdesign"
          className="product-thumbnail responsive-img"
        />
      </CSSTransition>
    </LazyLoad>
  );
};

class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageLoaded: false
    };

    this.setImageLoaded = this.setImageLoaded.bind(this);
  }

  setImageLoaded() {
    this.setState({
      imageLoaded: true
    });
  }

  render() {
    const props = this.props;
    const ReadMore = (
      <p className="ProductItem-more">
        <svg className="icon icon-price-tag">
          <use xlinkHref="#icon-price-tag" />
        </svg>
        <span>Se info & pris</span>
      </p>
    );

    const NorTitle = props.noTitle ? (
      <p className="ProductItem-no-title">{decode(props.noTitle)} (NO)</p>
    ) : null;

    return (
      <li className="ProductItem">
        <Link to={`/produkt/${props.id}`}>
          <NewsSymbol isNews={props.isNews} />
          <ProductItemThumbnail
            imgData={props.thumbnailData}
            alt={props.title}
            imageLoaded={this.state.imageLoaded}
            setImageLoaded={this.setImageLoaded}
          />
          <h2 className="ProductItem-title">{decode(props.title)}</h2>
          {NorTitle}
          {ReadMore}
        </Link>
      </li>
    );
  }
}

export default ProductItem;
